@font-face {
  font-family: Brandon;
  font-weight: 400;
  src: url('/fonts/BrandonGrotesque-Regular.woff') format('woff');
}

@font-face {
  font-family: Brandon;
  font-weight: 500;
  src: url('/fonts/BrandonGrotesque-Medium.woff') format('woff');
}

@font-face {
  font-family: Brandon;
  font-weight: 700;
  src: url('/fonts/BrandonGrotesque-Bold.woff') format('woff');
}

@font-face {
  font-family: Brandon;
  font-weight: 900;
  src: url('/fonts/BrandonGrotesque-Black.woff') format('woff');
}

body {
  font-family: Brandon, sans-serif;
}
